/*
 * @Author: your name
 * @Date: 2023-02-17 15:53:09
 * @LastEditTime: 2023-02-21 17:43:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \official\src\api\index.js
 */
import service from './utils/request'

// 查询官网所有产品
export function queryAllContent(params) {
  return service.request({
    method: 'POST',
    url: '/elifesales/api/n2_iss/life/officialWeb/view/queryAllContent',
    data: params,
  })
}
// 查询首页产品静态资源
export function getStatic(params) {
  return service.request({
    method: 'POST',
    url: '/elifesales/api/n2_iss/life/officialWeb/view/getStatic',
    data: params,
  })
}
// 查询条款
export function getDisclaimerAndTerm(params) {
  return service.request({
    method: 'POST',
    url: '/elifesales/api/n2_iss/officialWeb/getDisclaimerAndTerm',
    data: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
// 图形验证码-验证图片
export function checkImageValidCode(params) {
  return service.request({
    method: 'POST',
    url: '/pa18shoplife/do/life/subscribe/checkImageValidCode',
    data: params,
  })
}
 // 图形验证码-获取验证图片
export function getImageValidCode(params) {
  return service.request({
    method: 'POST',
    url: '/pa18shoplife/do/verify/image/getImageValidCode',
    data: params,
  })
}
// PC官网详情页-预约弹窗接口
export function productDetailSendDownAction(params) {
  return service.request({
    method: 'POST',
    url: '/pa18shoplife/do/life/subscribe/productDetailSendDownAction',
    data: params,
  })
}
// PC官网首页-预约弹窗接口
export function productTypeSendDownAction(params) {
  return service.request({
    url: '/pa18shoplife/do/life/subscribe/productTypeSendDownAction',
    method: 'POST',
    data: params,
  })
}

// 查询工号接口
export function queryNchrmsInfo(params) {
  return service.request({
    url: '/pa18shoplife/do/life/nchrms/queryNchrmsInfo',
    method: 'POST',
    data: params,
  })
}
// 查询软文接口
export function queryCmsHelpList(params) {
  return service.request({
    url: '/elifesales/api/n2_iss/officialWeb/queryCmsHelpList',
    method: 'GET',
    params
  })
}
// 查询软文接口
export function getCmsById(params) {
  return service.request({
    url: '/elifesales/api/n2_iss/officialWeb/getCmsById',
    method: 'GET',
    params,
  })
}

// 登陆
export function uMRegister(params) {
  return service.request({
    url: '/pa18shoplife/do/life/msgboard/uMRegister',
    method: 'POST',
    data: params,
  })
}