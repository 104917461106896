import indexStatic from './indexStaticData.json';
import allContent from './allContent.json';

import * as Api from '@/api';

const home = {
  state: () => ({
    allContent,
    indexStatic,
  }),
  actions: {
    // 获取官网产品信息
    queryAllContent({ commit }) {      
      Api.queryAllContent().then(res => {
        if (res && res.data && res.data.data && res.data.data.length > 0) {          
          commit('queryAllContent', res.data.data);
        }
      })
    },
    // 获取首页静态资源
    getStatic({ commit }) {      
      Api.getStatic({
        nodeTemplateId: "OFFICIAL9INDEX9EE0531228171E053B"
      }).then(res => {
        if (res && res.data && res.data.data && res.data.data.staticVO) {
          commit('getStatic', res.data.data.staticVO);
        }
      })
    },
  },
  mutations: {
    queryAllContent(state, payload) {
      state.allContent = payload
    },
    getStatic(state, payload) {
      state.indexStatic = payload
    },
  },
  getters: {
    // 首页banner
    getTopColorVO (state) {
      return state.indexStatic.topColorVO.attrMap
    },   
    // 首页热销
    getIndexPopularVO (state) {
      const attrMap = state.indexStatic.indexPopularVO.attrMap.syProductList;
      let newAttrMap = [];
      attrMap.map(item => {
        const _attrMap = (state.allContent || []).find(pre => {
          return pre.productCode === item.productCode
        })        
        if (_attrMap && _attrMap.staticVO && _attrMap.staticVO.contentVO && _attrMap.staticVO.contentVO.attrMap) {
          const { hightLightRemark, mainTitle, subtitle, remark, priceDescription } = _attrMap.staticVO.contentVO.attrMap
          newAttrMap.push({
            ...item,
            hightLightRemark, 
            mainTitle, 
            subtitle, 
            remark, 
            priceDescription
          })   
        }
      })
      return newAttrMap.length > 0 ? newAttrMap : attrMap;
    },
    // 首页精选
    getIndexFeaturedVO (state) {
      const attrMap = state.indexStatic.indexFeaturedVO.attrMap.syProductList;
      let newAttrMap = [];
      attrMap.map(item => {
        const _attrMap = (state.allContent || []).find(pre => {
          return pre.productCode === item.productCode
        })
        if (_attrMap && _attrMap.staticVO && _attrMap.staticVO.contentVO && _attrMap.staticVO.contentVO.attrMap) {
          const { hightLightRemark, mainTitle, subtitle, remark, priceDescription } = _attrMap.staticVO.contentVO.attrMap
          newAttrMap.push({
            ...item,
            hightLightRemark, 
            mainTitle, 
            subtitle, 
            remark, 
            priceDescription
          })   
        }
      })
      return newAttrMap.length > 0 ? newAttrMap : attrMap;
    },
    // 获取列表数据
    getList(state) {      
      let newAttrMap = [];
      state.allContent.map(item => {
        const { productCode, productName, productSalesCode } = item
        const cont = item.staticVO.contentVO.attrMap
        newAttrMap.push({
          productCode,
          productName,
          productSalesCode,
          ...cont
        })
      })
      return newAttrMap
    }
  }
}

export default home;

