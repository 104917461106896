/*
 * @Author: your name
 * @Date: 2023-02-15 17:30:20
 * @LastEditTime: 2023-03-23 18:05:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \official\src\main.js
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@babel/polyfill'
import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './style/common.css'
import './style/e-index.css'
import './style/zi.css'
import './style/servicehelp.css'

Vue.prototype.axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
